type LinkItemProps = {
    url: string;
    image: string;
    name: string;
};

export const LinkItem = ( props: LinkItemProps ) => {
    return (
      <div className="link-item">
        <div className="link-item-name">
            <a href={props.url}>{props.name}</a>
        </div>
        <div className="link-item-image">
            <img src={props.image} alt={props.name} />
        </div>
      </div>
    );
  };